import React, { useContext } from "react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { LocalizedContext } from "../../services/LocalizedContextService"
import { Player, ControlBar, ClosedCaptionButton } from "video-react";

import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import "./styles.scss";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { RichTextSerializers } from "../../utils/richTextSerializers";

const PromoBlockV3 = (props: any) => {
  const { ctaLink, ctaLinkReference, ctaLabel, _rawCopyText, bgColor, _rawImage, videoAltText } = props;
  const { language } = useContext(LocalizedContext);
  const { sanityId, sanityDataset } = useSiteMetadata(language);
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  let isPDF = false;
  if (ctaLink) {
    isPDF = ctaLink.asset?.asset?.url.includes("pdf");
  }

  return (
    <section className={`rx-c-featured rx-theme-${bgColor?.title?.toLowerCase() || "teal"}`}>
      <Container fluid>
        <Row>
          <Col sm="6">
            <div className="rx-c-featured-copy">
              <div className="rx-c-featured-copy--text">
                <BlockContent blocks={_rawCopyText} serializers={RichTextSerializers()} />
              </div>

              {ctaLabel && (
                <a
                  className="rx-c-featured-copy--btn"
                  href={ctaLinkReference || ctaLink?.asset?.asset?.url}
                  rel="noreferrer"
                  download={isPDF ? true : false}
                >
                  {ctaLabel}
                </a>
              )}
            </div>
          </Col>
          <Col sm="6">
            {props.video ? (
              <div className="rx-c-featured-video">
                <Player autoPlay muted loop>
                  <source src={props.video?.asset?.url} type="video/mp4" />
                  {videoAltText?.asset && <track src={videoAltText.asset.url} kind="captions" />}
                  <ControlBar autoHide={false}>
                    <ClosedCaptionButton order={7} />
                  </ControlBar>
                </Player>
              </div>
            ) : _rawImage ? (
              <div className="rx-c-featured-image">
                <picture
                  className="placeholder"
                  style={
                    _rawImage?.asset?.metadata
                      ? {
                          paddingTop: `56.25%`
                        }
                      : undefined
                  }
                >
                  <source
                    media={"(min-width: 1200px)"}
                    srcSet={
                      urlBuilder
                        .image(_rawImage)
                        .auto("format")
                        .quality(80)
                        .width(660)
                        .height(502)
                        .format("webp")
                        .url() || undefined
                    }
                  />
                  <img
                    src={
                      urlBuilder
                        .image(_rawImage)
                        .auto("format")
                        .quality(80)
                        .width(660)
                        .height(502)
                        .format("webp")
                        .url() || undefined
                    }
                    alt={_rawImage?.alt}
                    loading={"lazy"}
                    className="img-fluid"
                  />
                </picture>
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PromoBlockV3;
